var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Сплиты")]),_c('stocks-splits-filters',{attrs:{"table-params":_vm.tableParams},on:{"change-field":_vm.changeTableParams}}),_c('data-table',{ref:"dataTable",attrs:{"url":"stocks/splits","headers":_vm.headers,"table-params":_vm.tableParams},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(split.date))+" ")]}},{key:"item.from",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm.formateNumber(split.from))+":"+_vm._s(_vm.formateNumber(split.to))+" ")]}},{key:"item.source",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm.sourceMap[split.source])+" ")]}},{key:"item.createdAt",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(split.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusMap[split.status])+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }